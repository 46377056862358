@import "src/style/base";
//@import "~bulma/bulma";

.zhuanlan-render-root {
  text-align: left;
  padding: 10px 30px 60px 330px;
  line-height: 1.4;

  p {
    margin-bottom: 16px;
    line-height: 1.5;
    letter-spacing: 1px;
  }

  .zhuanlan-title {
    max-width: 760px;
    margin: 10px auto 20px;

    .title {
      font-size: 32px;
      margin-bottom: 12px;
    }

    .author {
      font-size: 14px;
      opacity: .7;
    }

    hr {
      margin-bottom: 6px;
      margin-top: 0;
    }
  }

  .content-wrapper {
    max-width: 760px;
    margin: auto;
  }

  h1 {
    font-size: 28px;
    font-weight: 600;
    margin: 12px 0 10px 0;
  }

  h2 {
    font-size: 26px;
    font-weight: 500;
    margin: 12px 0 6px 0;
  }

  h3 {
    font-size: 23px;
    font-weight: 500;
    margin: 12px 0 3px 0;
  }

  h3 {
    font-size: 20px;
    font-weight: 500;
    margin: 12px 0 3px 0;
  }

  h4 {
    font-size: 17px;
    font-weight: 500;
    margin: 12px 0 3px 0;
  }

  pre {
    background: none;
    margin: 14px 0;
    padding: 12px !important;

    code {
      font-family: monospace;
    }
  }

  li {
    margin-left: 1.5em;
  }

  ul,
  ol {
    margin-top: 6px;
    margin-bottom: 10px;
  }

  .zhuanlan-img {
    margin-bottom: 12px;
  }

  .content-highlight {
    text-decoration: underline;
    text-decoration-color: orange;
    cursor: pointer;

    &.has-annotation {
      background: #ffa50033;
    }
  }

  .loading-bar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1000;

    .fill {
      height: 3px;
      background: $color-primary;
      box-shadow: $color-primary 0px 0px 10px, $color-primary 0px 0px 10px;
      transition: all 0.2s;
    }
  }

  h1 {
    //color: $color-primary;
  }

  strong {
    color: $color-primary;
  }

  a {
    color: $color-primary;

    svg {
      width: 14px;
      height: 14px;
      fill: $color-primary;
      margin: -2px 0 -2px 2px;
    }

    &:visited {
      color: adjust-color($color-primary, $lightness: -10%);
    }
  }
}

.highlight-button-wrapper {
  position: absolute;
  text-align: center;
  z-index: 10;

  .highlight-buttons {
    display: flex;
    margin: auto;
    width: 300px;
    font-weight: 600;
    //background: adjust-color($color-primary, $lightness: +40%, $saturation: +10%);
    cursor: pointer;
    box-shadow: #aaaaaa99 0px 0px 3px;
    border: 1px solid #aaaaaa55;

    .highlight-button {
      user-select: none;
      position: relative;

      svg {
        //position: absolute;
        //left: 20px;
        //top: 7px;
        margin-right: 10px;
      }

      backdrop-filter: blur(2px);
      padding: 4px;

      flex-grow: 1;
      background: #f9f9f9cc;
      border-right: 1px solid #aaaaaa88;

      &:last-of-type {
        border-right: none;
      }

      transition: background 0.2s;

      &:hover {
        background: #fcefd9dd;
      }

      &.del-button {
        &:hover {
          background: #fcd9dedd;
        }
      }
    }
  }
}

.annotation-root {
  textarea {
    border: none;
    font-size: 16px;
    line-height: 1.4;
    padding: 10px;
    min-width: 300px;
    min-height: 80px;
    max-width: 500px;
    max-height: 200px;
  }
}

.inline-svg {
  display: inline-block;
  width: 16px;
  height: 16px;
  margin: -2px;
}

.dark-mode {
  background: rgb(23, 26, 28) !important;

  :not([style*="color:"]) {
    color: rgb(212, 206, 193) !important;
  }

  svg {
    fill: rgb(212, 206, 193) !important;
  }

  h1:not([style*="color:"]),
  h2:not([style*="color:"]),
  h3:not([style*="color:"]),
  h4:not([style*="color:"]),
  h5:not([style*="color:"]),
  h6:not([style*="color:"]) {
    color: rgb(255, 197, 171) !important;
  }

  :not([style*="background-color:"]) {
    background-color: rgb(23, 26, 28) !important;
  }

  .katex {
    :not([style*="background-color:"]) {
      background-color: initial !important;
    }
  }

  .drag-hint {
    background: rgb(45, 50, 53) !important;
  }

  .table thead td,
  .table thead th {
    border-width: 0 0 2px;
    color: #363636;
  }

  .table th {
    color: #363636;
  }

  a:not([style*="color:"]) {
    color: rgb(195, 230, 244) !important;

    svg {
      fill: rgb(195, 230, 244) !important;
    }
  }

  :not([style*="border-color:"]),
  ::before,
  ::after {
    border-color: rgb(123, 146, 158) !important;
    border-top-color: rgb(123, 146, 158) !important;
    border-right-color: rgb(123, 146, 158) !important;
    border-bottom-color: rgb(123, 146, 158) !important;
    border-left-color: rgb(123, 146, 158) !important;
  }

  .highlight-button-wrapper {
    background: transparent !important;
  }

  .content-highlight.has-annotation {
    background: #ffa50014 !important;
  }
}
