.zhuanlan-img {
  text-align: center;

  img {
    max-width: 60%;
  }

  .zhuanlan-img-alt {
    color: #aaa;
    font-size: 12px;
  }
}
